import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components/macro"

const Quote:React.FC<{
  by: string, 
  source: string, 
  children:React.ReactNode,
  img_src: string,
}> = ({ by, source, children, img_src}) => {
  return (
    <Wrapper>
      <Headshot alt={by} src={img_src} />
      <QuoteContent>{children}</QuoteContent>
      <figcaption>
        <Author><SourceLink target="_blank" href={source}>{by}</SourceLink></Author>
      </figcaption>
    </Wrapper>
  )
}

const Wrapper = styled.figure`
  position: relative;
  width: 100%;
`

const QuoteContent = styled.blockquote`
  margin: 0;
  background-color: var(--color-gray-900);
  padding: 20px 16px 40px 16px;
  border-radius: 8px;
  font-style: italic;

  &:before {
    content: '“';
  }

  &:after {
    content: '”';
  }

  & em {
    font-weight: var(--font-weight-bold);
  }
`

const Headshot = styled.img`
  border-radius: 50%;
  border: 2px solid var(--color-gray-1000);
  position: absolute;
  width: 75px;
  height: 75px;
  bottom: -10px;
  left: 20px;
`

const Author = styled.cite`
  display: inline-block;
  text-align: left;
  margin-top: -16px;
  margin-left: 74px;
  padding: 12px 20px 12px 32px;
  background-color: var(--color-secondary);
  color: var(--color-gray-1000);
  font-weight: var(--font-weight-medium);
  font-size: 1.2rem;
  border-radius: 4px;
  font-style: normal;
`

const SourceLink = styled.a`
color: var(--color-gray-1000);
`

export default Quote