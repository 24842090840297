import * as React from "react"
import AboutMe from "../components/about-me"
import CenterRow from "../components/center-row"
import FlaredEmphasis from "../components/flared-emphasis"
import HeroSection from "../components/hero-section"
import QuoteSection from "../components/quote-section"

const IndexPage = () => {
  return (
    <>
      <HeroSection />
      <CenterRow>
        <img src="studying.png" alt="peace of mind" />
      </CenterRow>
      <QuoteSection />
      <AboutMe />
    </>
  )
}

export default IndexPage
