import React from 'react'
import styled from 'styled-components'
import FullRow from './full-row'
import GridContainer from './grid-container'

const HeroSection:React.FC<{}> = () => {
  return ( 
    <FullRow>
      <Wrapper>
          <Content>
            <h1>Hey! I'm Stacy Vicknair.</h1>
            <p>
              I help social enterprise SaaS founders unclog their software development processes.
            </p>
          </Content>
      </Wrapper>
    </FullRow>
  )
}

const Wrapper = styled(GridContainer)`
  background-color: var(--color-primary);
  color: var(--color-gray-1000);
`

const Content = styled.div`
padding: 56px 24px 80px 24px;
grid-column: 1 / 4;

& > p {
  font-size: 1.1rem;
}

& > h1 {
  font-size: 1.5rem;
}

@media (min-width: 992px) {
  grid-column: 2 / 5;

  & > p {
    font-size: 1.3rem;
  }

  & > h1 {
    font-size: 1.8rem;
  }
}
`

export default HeroSection