import React from 'react'
import styled from 'styled-components'
import CenterRow from './center-row'
import GridContainer from './grid-container'
import JaggedSection from './jagged-section'
import Quote from './quote'

const QuoteSection:React.FC<{}> = () => {
  return <JaggedSection backgroundColor='var(--color-primary)'>
    <Wrapper>
      <CenterRow wide>
      <SectionTitle>Kind Words</SectionTitle>
      <QuoteWrapper>
      <Quote
        by="Caroline Caselli"
        source="https://www.linkedin.com/in/carolinecaselli/"
        img_src="/carolinecaselli_square.jpg"
      >
        Stacy is a joy to work with. He asks the right questions so that the highest priority items are tackled first, 
        something that is worth its weight in gold in startups. Finding an engineering leader who is simultaneously 
        technically brilliant and business savvy has been transformative for the business and the product. After being 
        in the trenches at both small and large companies, he has strong understanding of <em>replicable systems that actually 
        work well for humans</em>. I recommend working with him unreservedly.
      </Quote>
      </QuoteWrapper>
      <QuoteWrapper>
      <Quote
        by="Christina Asbaty"
        source="https://www.linkedin.com/in/christinaasbaty/"
        img_src="/christinaasbaty.jpeg"
      >
        Stacy <em>took time to get to know our process and understand our culture</em> to then add value and become an amazing mentor and guide 
        to our team.  I can specifically think of a story where we had a pretty big mess up and he walked the team through a blameless exercise to help 
        take responsibility and put forth new practices to handle the situation differently in the future. He has been a great leader and 
        support to our team. His demeanor is thoughtful and approachable which is exactly what we need in this leadership role.
      </Quote>
      </QuoteWrapper>
      <Quote
        by="Drake Lambert"
        source="https://www.linkedin.com/in/drakelambert/"
        img_src="/drake_square.png"
      >
        Stacy listened attentively, was slow to speak, and led me to discovering answers without spoon feeding. 
        This resulted in a much deeper understanding of his advice, but it also taught me skills on how to think
        about complex problems in leadership and software delivery that I could then use on my own. <em>In meetings 
          with Stacy I’ve learned as much about myself as I have about working in tech.</em>
      </Quote>
      </CenterRow>
    </Wrapper>
  </JaggedSection>
}

const Wrapper = styled(GridContainer)`
padding: 0 24px 32px 24px;
`

const SectionTitle = styled.h1`
  color: var(--color-gray-1000);
  padding: 24px 0;
  text-align: center;
`

const QuoteWrapper = styled.div`
  padding-bottom: 24px;
`

export default QuoteSection